import React from 'react';
import PropTypes from 'prop-types';
import Box from 'common/src/components/Box';
import Text from 'common/src/components/Text';
import Heading from 'common/src/components/Heading';
import Container from 'common/src/components/UI/Container';
import Image from 'common/src/components/Image';
import { List, ListItem } from '../scrollFixed.style';
import '../scrollfixed.css';

import scrollTo from 'gatsby-plugin-smoothscroll';

import IdentifyImg from 'common/src/assets/image/hosting/ScrollFixed/Platform/Rapbot/Identify.svg';
import DevelopImg from 'common/src/assets/image/hosting/ScrollFixed/Platform/Rapbot/Develop.svg';
import DeployImg from 'common/src/assets/image/hosting/ScrollFixed/Platform/Rapbot/Deploy.svg';
import MonitorImg from 'common/src/assets/image/hosting/ScrollFixed/Platform/Rapbot/Monitor.svg';

class ScrollfixedRapBot extends React.Component {
  componentDidMount() {
    if (typeof window !== 'undefined') {
      function ScrollfixedRapBot() {
        const content = document.querySelector(
          '.scrollmain_right_content.rapbot'
        );
        if (content != null) {
          const isInViewport = function (data) {
            if (data != null) {
              let bounding = data.getBoundingClientRect();
              let boundingTopOffset = bounding.top - 105;
              let boundingBottom = bounding.bottom;
              // let boundingBottomOffset = bounding.bottom;
              return boundingTopOffset <= 0 && boundingBottom >= 0;
            }
          };

          const content1 = document.querySelector(
            '.scrollmain_right_content.rapbot._ct1'
          );
          const content2 = document.querySelector(
            '.scrollmain_right_content.rapbot._ct2'
          );
          const content3 = document.querySelector(
            '.scrollmain_right_content.rapbot._ct3'
          );
          const content4 = document.querySelector(
            '.scrollmain_right_content.rapbot._ct4'
          );

          if (isInViewport(content1)) {
            document
              .querySelectorAll('.scrollmain_left_items li')[0]
              .classList.add('active');
          } else {
            document
              .querySelectorAll('.scrollmain_left_items li')[0]
              .classList.remove('active');
          }
          if (isInViewport(content2)) {
            document
              .querySelectorAll('.scrollmain_left_items li')[1]
              .classList.add('active');
          } else {
            document
              .querySelectorAll('.scrollmain_left_items li')[1]
              .classList.remove('active');
          }
          if (isInViewport(content3)) {
            document
              .querySelectorAll('.scrollmain_left_items li')[2]
              .classList.add('active');
          } else {
            document
              .querySelectorAll('.scrollmain_left_items li')[2]
              .classList.remove('active');
          }
          if (isInViewport(content4)) {
            document
              .querySelectorAll('.scrollmain_left_items li')[3]
              .classList.add('active');
          } else {
            document
              .querySelectorAll('.scrollmain_left_items li')[3]
              .classList.remove('active');
          }
        }
      }

      window.addEventListener('scroll', ScrollfixedRapBot);
    }
  }
  render() {
    const {
      sectionWrapper,
      row,
      col,
      title,
      title2,
      titleSlider,
      description,
      descriptionSlider,
      textArea,
      contentArea,
      listitems,
      ...props
    } = this.props;
    return (
      <>
        <Box {...sectionWrapper}>
          <Container>
            <Box className="scrollmain">
              <Heading {...title} content="Four Steps to Automation" />
              <Heading {...title2} content="Go ahead cross the chasm" />
              <Box className="scrollmain_left">
                <Text
                  {...description}
                  content="Automate in days with our methodical, process-based approach that is low code, quick win focused and rapid deployment. Target 50%+ ROI within months."
                />
                <div className="empty_space20" />
                <List className="scrollmain_left_items">
                  <ListItem {...listitems} onClick={() => scrollTo('#__ct1')}>
                    Identify
                  </ListItem>
                  <ListItem {...listitems} onClick={() => scrollTo('#__ct2')}>
                    Develop
                  </ListItem>
                  <ListItem {...listitems} onClick={() => scrollTo('#__ct3')}>
                    Deploy
                  </ListItem>
                  <ListItem {...listitems} onClick={() => scrollTo('#__ct4')}>
                    Monitor
                  </ListItem>
                </List>
                {/* <div className="empty_space20" /> */}
              </Box>
              <Box className="scrollmain_right">
                <Box className="scrollmain_right_container">
                  <Box className="scrollmain_right_content rapbot _ct1">
                    <Box id="__ct1" className="topic" />
                    <Heading {...titleSlider} content="Identify" />
                    <Image src={IdentifyImg} alt="Identify" />
                    <Text
                      {...descriptionSlider}
                      content="Identify processes that can provide the highest ROI. Once identified, study the process. Then re-design the process with clearly defined actions and failure points."
                    />
                  </Box>
                  <Box className="scrollmain_right_content rapbot _ct2">
                    <Box id="__ct2" className="topic" />
                    <Heading {...titleSlider} content="Develop" />
                    <Image src={DevelopImg} alt="Develop" />
                    <Text
                      {...descriptionSlider}
                      content="Create scripts and  identify configurable elements to handle multiple scenarios. Set-up courses of action for failure points and recovery modes. Consider whether the bot will be independent or collaborative."
                    />
                  </Box>
                  <Box className="scrollmain_right_content rapbot _ct3">
                    <Box id="__ct3" className="topic" />
                    <Heading {...titleSlider} content="Deploy" />
                    <Image src={DeployImg} alt="Deploy" />
                    <Text
                      {...descriptionSlider}
                      content="Deploy on any platform either Windows, Linux or Mac. Deploy for both unattended and attended bots. Deploy to run as trigger-based or schedule-based."
                    />
                  </Box>
                  <Box className="scrollmain_right_content rapbot _ct4">
                    <Box id="__ct4" className="topic" />
                    <Heading {...titleSlider} content="Monitor" />
                    <Image src={MonitorImg} alt="Monitor" />
                    <Text
                      {...descriptionSlider}
                      content="Continuous monitoring allows you to keep track of how the bot is performing. Based on failures or errors, or new scenarios, you can modify the bot to handle these better."
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
          </Container>
        </Box>
      </>
    );
  }
}

ScrollfixedRapBot.propTypes = {
  sectionWrapper: PropTypes.object,
  row: PropTypes.object,
  col: PropTypes.object,
  title: PropTypes.object,
  title2: PropTypes.object,
  titleSlider: PropTypes.object,
  description: PropTypes.object,
  descriptionSlider: PropTypes.object,
  textArea: PropTypes.object,
  contentArea: PropTypes.object,
  listitems: PropTypes.object,
};

ScrollfixedRapBot.defaultProps = {
  sectionWrapper: {
    as: 'section',
    pt: ['30px', '30px', '30px', '30px', '30px'],
    pb: ['40px', '40px', '40px', '40px', '40px'],
    // pb: ['60px', '80px', '40px', '80px', '80px'],
    id: 'scroll_fixed',
    backgroundColor: '#191c3c',
  },
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: '-15px',
    mr: '-15px',
  },
  col: {
    pr: '15px',
    pl: '15px',
    width: ['100%', '100%', '100%', '49%', '49%'],
  },
  textArea: {
    width: ['100%', '100%', '100%', '49%', '49%'],
  },
  contentArea: {
    width: ['100%', '100%', '80%', '80%', '80%'],
    margin: '0 auto',
  },
  title: {
    fontSize: ['25px', '25px', '30px', '35px', '35px'],
    fontWeight: '300',
    // color: '#0f2137',
    color: '#fff',
    letterSpacing: '-0.025em',
    mb: '10px',
    pt: '25px',
    textAlign: 'center',
  },
  title2: {
    fontSize: ['20px', '20px', '22px', '22px', '22px'],
    fontWeight: '300',
    // color: '#0f2137',
    color: '#fff',
    // letterSpacing: '-0.025em',
    mb: '0',
    pt: '0',
    textAlign: 'center',
  },
  description: {
    fontSize: ['16px', '18px', '18px', '18px', '18px'],
    // color: '#343d48cc',
    // color: '#252525',
    color: '#fff',
    lineHeight: '1.5',
    mt: '30px',
    mb: '5px',
  },
  titleSlider: {
    fontSize: ['22px', '22px', '25px', '30px', '30px'],
    fontWeight: '300',
    // color: '#0f2137',
    color: '#fff',
    letterSpacing: '-0.025em',
    mb: '30px',
    pt: '0px',
    textAlign: 'center',
    className: 'scrollFixTitle',
  },
  descriptionSlider: {
    fontSize: ['16px', '16px', '16px', '16px', '16px'],
    // color: '#343d48cc',
    // color: '#252525',
    color: '#fff',
    lineHeight: '1.5',
    mt: '25px',
    mb: '5px',
    textAlign: 'center',
  },
  listitems: {
    fontSize: ['16px', '18px', '18px', '18px', '18px'],
    color: '#fff',
    lineHeight: '1.5',
    mb: '15px',
  },
};

export default ScrollfixedRapBot;
