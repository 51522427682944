import React from 'react';
import PropTypes from 'prop-types';
import Fade from 'react-reveal/Fade';
import Box from 'common/src/components/Box';
import Text from 'common/src/components/Text';
import Heading from 'common/src/components/Heading';
import Button from 'common/src/components/Button';
import Card from 'common/src/components/Card';
import Image from 'common/src/components/Image';
import Container from 'common/src/components/UI/Container';
import BgImageDark from '../../BgImagedark';
import '../infoplatform.css';

import sample from 'common/src/assets/image/hosting/author-3.jpg';

const InfoPlatformSectionDark = ({
  sectionWrapper,
  title,
  subtitle,
  description1,
  description2,
  textArea,
}) => {
  return (
    <Box {...sectionWrapper}>
      <Box className="section_background_blue">
        <BgImageDark />
        <Container>
          <Box {...textArea}>
            <Fade bottom cascade>
              <Heading {...title} content="Where Can I Use RAPBot?" />
              <Text
                {...subtitle}
                content="High-volume, rule-based, data-intensive processes are the golden area for RAPBot"
              />
              <Text
                {...description1}
                content="Accounts Payables, Accounts Receivables, logging into applications, support tickets, payroll, generating reports and much more. Automate processes in mere minutes. Eliminate the tedium and errors that typically plague them, and reap rapid ROI."
              />
              {/* <Text
                {...description2}
                content="Automate these processes in mere minutes. Eliminate the tedium and errors that typically plague them, and reap rapid ROI."
              /> */}
            </Fade>
          </Box>
        </Container>
      </Box>
    </Box>
  );
};

InfoPlatformSectionDark.propTypes = {
  sectionWrapper: PropTypes.object,
  textArea: PropTypes.object,
  title: PropTypes.object,
  subtitle: PropTypes.object,
  description1: PropTypes.object,
  description2: PropTypes.object,
};

InfoPlatformSectionDark.defaultProps = {
  sectionWrapper: {
    as: 'section',
    // pt: ['25px', '25px', '25px', '30px', '30px'],
    // pb: ['60px', '80px', '40px', '80px', '80px'],
    id: 'info_platform2',
    pb: '0',
    // pb: '25px',
  },
  textArea: {
    width: [1, '90%', '90%', '80%', '80%'],
    margin: '0 auto',
  },
  title: {
    fontSize: ['30px', '30px', '35px', '40px', '45px'],
    fontWeight: '300',
    // color: 'rgb(242, 134, 58) !important',
    color: '#fff',
    letterSpacing: '-0.025em',
    mt: '5px',
    mb: '10px',
    textAlign: 'center',
  },
  subtitle: {
    fontSize: ['20px', '20px', '22px', '22px', '22px'],
    fontWeight: '600',
    color: '#fff',
    mt: 0,
    mb: ['25px', '25px', '25px', '25px', '25px'],
    textAlign: 'center',
  },
  description1: {
    fontSize: ['16px', '16px', '18px', '18px', '18px'],
    // color: '#343d48cc',
    color: '#fff',
    textAlign: 'left',
    lineHeight: '1.5',
    mt: '0',
    mb: '5px',
    textAlign: 'center',
  },
  description2: {
    fontSize: ['16px', '16px', '18px', '18px', '18px'],
    // color: '#343d48cc',
    color: '#fff',
    textAlign: 'left',
    lineHeight: '1.5',
    mt: '0',
    mb: '20px',
    textAlign: 'center',
  },
};

export default InfoPlatformSectionDark;
