import React from 'react';
import { ThemeProvider } from 'styled-components';
import Sticky from 'react-stickynode';
import { hostingTheme } from 'common/src/theme/hosting';
import {
  GlobalStyle,
  ContentWrapper,
} from '../../containers/Hosting/hosting.style';
import { ResetCSS } from 'common/src/assets/css/style';
import Navbar from '../../containers/Hosting/Navbar';
import Footer from '../../containers/Hosting/Footer';
import Ctasection from '../../containers/Hosting/Ctasection';
import { DrawerProvider } from 'common/src/contexts/DrawerContext';
import { ParallaxProvider } from 'react-scroll-parallax';
import SEO from '../../components/seo';

import ScrollfixedRapBot from '../../containers/Hosting/ScrollFixed/rapbot';
import BannerSectionPlatform from '../../containers/Hosting/Banner/BannerPlatform';
import InfoPlatformSection from '../../containers/Hosting/InfoPlatform';
import InfoPlatformSectionDark from '../../containers/Hosting/InfoPlatform/Rapbot';
import IconFloatingsection from '../../containers/Hosting/IconFloating/Platform/Rapbot';
import TopRibbon from '../../containers/Hosting/TopRibbon';

const RAPBotPage = () => {
  const name = 'RAPBot';
  return (
    <ThemeProvider theme={hostingTheme}>
      <ParallaxProvider>
        <SEO
          title="Automate end to end with Intelligent RPA | RAPBot"
          description="Rapid Acceleration Partners' RAPBot is an intelligent RPA tool. Automate workflows providing unlimited scalability and minimal manual intervention. Book a free demo here."
        />

        <ResetCSS />
        <GlobalStyle />
        <TopRibbon />
        <ContentWrapper className="RAPBotPage">
          <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
            <DrawerProvider>
              <Navbar />
            </DrawerProvider>
          </Sticky>
          <BannerSectionPlatform name={name} />
          <InfoPlatformSection name={name} />
          <InfoPlatformSectionDark />
          <ScrollfixedRapBot />
          <IconFloatingsection />
          <Ctasection name={name} />
          <Footer />
        </ContentWrapper>
      </ParallaxProvider>
    </ThemeProvider>
  );
};

export default RAPBotPage;
