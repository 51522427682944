import React from 'react';
import PropTypes from 'prop-types';
import Fade from 'react-reveal/Fade';
import Box from 'common/src/components/Box';
import Text from 'common/src/components/Text';
import Heading from 'common/src/components/Heading';
import Container from 'common/src/components/UI/Container';
import Image from 'common/src/components/Image';

import { useStaticQuery, graphql } from 'gatsby';
import { Link } from 'gatsby';

import AutomateImg from 'common/src/assets/image/hosting/Icons/Platform/Automate Any Task.png';
import BoostImg from 'common/src/assets/image/hosting/Icons/Platform/Boost Operational Efficiency.png';
import RapidImg from 'common/src/assets/image/hosting/Icons/Platform/Rapid ROI & Low TCA.png';
import UnlimitedImg from 'common/src/assets/image/hosting/Icons/Platform/Unlimited Scalability.png';
import PlatformImg from 'common/src/assets/image/hosting/Icons/Platform/Platform Agnostic.png';
import LowImg from 'common/src/assets/image/hosting/Icons/Platform/Low Infrastructure Footprint.png';
import InbuiltImg from 'common/src/assets/image/hosting/Icons/Platform/Inbuilt Items.png';

import '../../iconfloating.css';

const IconFloatingsection = ({
  sectionWrapper,
  row,
  col,
  col3,
  col4,
  title,
  description,
  iconHead,
  iconsubHead,
  iconDescription,
  textArea,
}) => {
  return (
    <>
      <Box {...sectionWrapper} className="Icon_Floating_Container RapBot">
        <Container>
          <Heading {...title} content="Why RAPBot?" />
          <Text
            {...description}
            className="text_center"
            content="RAPBot is an industry-leading RPA tool that makes it extremely easy to automate just about anything."
          />
          <Fade bottom cascade delay={50}>
            <Box {...row} className="margin_auto flexjustifyaround">
              <Box {...col4} className="icontext_card icontext_card_whitebg">
                <Box className="flexaligncenter">
                  <ul>
                    <li>
                      <Image
                        src={AutomateImg}
                        alt="Automate Any Task"
                        className="icon_image"
                      />
                    </li>
                    <li {...iconHead}>Automate Any Task</li>
                    <li {...iconDescription}>
                      Mimic human action to copy and paste data, read and write
                      to interfaces, make calculations and more. The RAPBot also
                      performs back-end actions like calling an API or
                      reading/writing to a database.
                    </li>
                  </ul>
                </Box>
              </Box>
              <Box {...col4} className="icontext_card icontext_card_whitebg">
                <Box className="flexaligncenter">
                  <ul>
                    <li {...iconHead}>
                      <Image
                        src={BoostImg}
                        alt="Boost Operational Efficiency"
                        className="icon_image"
                      />
                    </li>
                    <li {...iconHead}>Boost Operational Efficiency</li>
                    <li {...iconDescription}>
                      RAPBot’s automation accelerates processes, cuts costs and
                      minimizes errors providing greater operational efficiency.
                    </li>
                  </ul>
                </Box>
              </Box>
              <Box {...col4} className="icontext_card icontext_card_whitebg">
                <Box className="flexaligncenter">
                  <ul>
                    <li>
                      <Image
                        src={RapidImg}
                        alt="Rapid ROI & Low TCA"
                        className="icon_image"
                      />
                    </li>
                    <li {...iconHead}>Rapid ROI & Low TCA</li>
                    <li {...iconDescription}>
                      Automation enables up to 70% reduction in processing costs
                      which leads to rapid ROI. Our simple licensing model
                      reduces total cost of automation significantly.
                    </li>
                  </ul>
                </Box>
              </Box>
              <Box {...col4} className="icontext_card icontext_card_whitebg">
                <Box className="flexaligncenter">
                  <ul>
                    <li>
                      <Image
                        src={UnlimitedImg}
                        alt="Unlimited Scalability"
                        className="icon_image"
                      />
                    </li>
                    <li {...iconHead}>Unlimited Scalability</li>
                    <li {...iconDescription}>
                      The sky is the limit. Actually that’s not true, there is
                      no limit on scaling operations with RAPBot. Deploy across
                      platforms – desktop, cloud, server or a virtual machine.
                    </li>
                  </ul>
                </Box>
              </Box>
            </Box>
            <Box {...row} className="flexjustifycenter">
              <Box {...col3} className="icontext_card icontext_card_whitebg">
                <Box className="flexaligncenter">
                  <ul>
                    <li>
                      <Image
                        src={PlatformImg}
                        alt="Platform Agnostic "
                        className="icon_image"
                      />
                    </li>
                    <li {...iconHead}>Platform Agnostic </li>
                    <li {...iconDescription}>
                      There are no platform restrictions for RAPBot so it easily
                      augments your existing systems and can work across
                      platforms – Windows, Linux or Mac.
                    </li>
                  </ul>
                </Box>
              </Box>
              <Box {...col3} className="icontext_card icontext_card_whitebg">
                <Box className="flexaligncenter">
                  <ul>
                    <li>
                      <Image
                        src={LowImg}
                        alt="Low Infrastructure Footprint"
                        className="icon_image"
                      />
                    </li>
                    <li {...iconHead}>Low Infrastructure Footprint</li>
                    <li {...iconDescription}>
                      RAPBot has minimal infrastructure requirements and can
                      work with a very low infrastructure footprint – as low as
                      1 CPU and 2GB of RAM.
                    </li>
                  </ul>
                </Box>
              </Box>
              <Box {...col3} className="icontext_card icontext_card_whitebg">
                <Box className="flexaligncenter">
                  <ul>
                    <li>
                      <Image
                        src={InbuiltImg}
                        alt="Inbuilt Items"
                        className="icon_image"
                      />
                    </li>
                    <li {...iconHead}>Inbuilt Items</li>
                    <li {...iconDescription}>
                      Credentialing, orchestrator, OCR all in one convenient
                      package. Pay nothing extra for licensing, unless you wish
                      to use our AI components.
                    </li>
                  </ul>
                </Box>
              </Box>
            </Box>
          </Fade>
        </Container>
      </Box>
      {/* end of rapbot */}
    </>
  );
};

IconFloatingsection.propTypes = {
  sectionWrapper: PropTypes.object,
  row: PropTypes.object,
  col: PropTypes.object,
  col3: PropTypes.object,
  col4: PropTypes.object,
  title: PropTypes.object,
  description: PropTypes.object,
  iconHead: PropTypes.object,
  iconsubHead: PropTypes.object,
  iconDescription: PropTypes.object,
  textArea: PropTypes.object,
};

IconFloatingsection.defaultProps = {
  sectionWrapper: {
    as: 'section',
    id: 'IconFloating_section',
    className: 'IconFloating_section',
    pt: ['15px', '15px', '15px', '15px'],
    background: 'transparent',
    pb: ['30px', '30px', '30px', '30px'],
  },

  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: '-15px',
    mr: '-15px',
  },
  col: {
    className: 'col',
    width: [1, 0.45, 0.45, 0.45],
    pr: '15px',
    pl: '15px',
    mb: '20px',
  },
  col3: {
    className: 'col3',
    width: [1, 0.44, 0.44, 0.23],
    mr: [1, '2%', '2%', '1%'],
    ml: [1, '2%', '2%', '1%'],
  },
  col4: {
    className: 'col4',
    width: [1, 0.46, 0.46, 0.23],
    mr: '0.15%',
    ml: '0.15%',
  },
  iconHead: {
    fontSize: ['18px', '18px', '20px', '20px'],
    className: 'iconHead',
  },
  iconsubHead: {
    fontSize: ['18px', '18px', '20px', '20px'],
    className: 'iconsubHead',
  },
  textArea: {
    width: ['100%', '85%', '85%', '85%', '80%'],
    margin: '0 auto',
  },
  iconDescription: {
    className: 'iconDescription',
    fontSize: ['18px', '18px', '20px', '20px'],
  },
  title: {
    fontSize: ['30px', '30px', '35px', '40px', '40px'],
    fontWeight: '300',
    color: '#0f2137',
    letterSpacing: '0.015em',
    mb: '25px',
    pt: '25px',
    textAlign: 'center',
  },
  description: {
    fontSize: ['16px', '16px', '18px', '18px', '18px'],
    color: '#252525',
    lineHeight: '1.5',
    mt: '25px',
    mb: '25px',
  },
};

export default IconFloatingsection;
